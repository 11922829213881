<template>
    <CCardBody>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
        <CCol xl="12" style="text-align: center;">
            <CSpinner
                v-if="isLoading"
                color="info"
                style="width:3rem;height:3rem;"
            />
        </CCol>
        <CRow class="padding-0" v-if="!isLoading">
            <CCol xl="4" class="text-center">
                <div v-if="url">
                    <img :src="url" class="c-avatars"/>
                </div>
            </CCol>
            <CCol xl="8">
                <label>NRP : {{form.nrp}}</label><br/>
                <label>Full Name : {{form.name}}</label><br/>
                <label>Email : {{form.email}}</label><br/>
                <label>Phone Number : {{form.phone}}</label>
                <CTextarea
                    label="Address"
                    vertical
                    rows="4"
                    v-model="form.address"
                    readonly
                />
            </CCol>
            <CCol xl="6">
                <label>Privilege : {{form.role?form.role.name:''}}</label><br/>
            </CCol>
            <CCol xl="6">
                <label>Telegram : {{form.telegram_id?form.telegram_id:''}}</label><br/>
            </CCol>
            <CCol xl="12">
                <label>Division : {{form.division?form.division.name:''}}</label>
            </CCol>
            <CCol xl="12">
                <div class="form-group" role="group">
                  <label>Air Base</label>
                  <multiselect
                    v-model="value_airports"
                    :options="airports"
                    track-by="value"
                    label="label"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    disabled>
                  </multiselect>
                  <small class="form-text text-muted w-100"></small>
                </div>
            </CCol>
        </CRow>
    </CCardBody>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DetailProfile',
  components: {
  },
  props: {
    valueairports: {
      type: Array,
      default: () => []
    },
    reload: false
  },
  data () {
    return {
      isLoading: false,
      roles: [],
      positions: [],
      airports: [],
      value_airports: this.valueairports,
      form: {
        uuid: '',
        nrp: '',
        name: '',
        profile_picture: null,
        address: '',
        phone: '',
        email: '',
        password: '',
        role_id: '',
        division_id: '',
        airport_id: [],
        telegram_id: ''
      },
      url: 'img/avatars/profile_img.svg',
    }
  },
  watch: {
    reload: {
      handler: function (val, oldVal) {
        if(val) { this.showData() }
      }
    }
  },
  created() {
    this.getAirport()
    this.showData()
  },
  methods: {
    ...mapActions('profile', ['showPFL']),
    ...mapActions('airport', ['indexAPT']),
    showData(){
      this.isLoading = true
      this.showPFL().then((response) => {
        this.form = response
        this.url = response.profile_picture?response.profile_picture:'img/avatars/profile_img.svg'
        this.form.profile_picture = null
        this.filterAirport(response.airport_rights)
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    async getAirport(){
        this.isLoading = true
        await this.indexAPT().then((response) => {
            response.forEach((item, index) => {
                this.airports[index] = { value: item.id, label: item.name }
            })
            this.isLoading = false
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
            this.isLoading = false
        })
    },
    filterAirport(val) {
      this.value_airports = val.map(function (obj) {
        return { value: obj.id, label: obj.name }
      })
    },
  }
}
</script>
<style scoped>
  .c-avatars {
    border-radius: 50em;
     height: 18em;
     width: 18em;
    border: 3px solid black;
  }
</style>