<template>
  <CCardBody>
    <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCol xl="12" style="text-align: center;">
        <CSpinner
          v-if="isLoading"
          color="info"
          style="width:3rem;height:3rem;"
        />
      </CCol>
      <CForm v-if="!isLoading">
        <CInput
          label="NRP"
          :description="errors.nrp"
          placeholder="Please fill NRP"
          type="text"
          vertical
          v-model="form.nrp"
        />
        <CInput
          label="Full Name"
          :description="errors.name"
          placeholder="Please fill full name"
          type="text"
          vertical
          v-model="form.name"
        />
        <CInput
          label="Email"
          :description="errors.email"
          placeholder="Please fill email"
          type="email"
          vertical
          v-model="form.email"
        />
        <CInput
          label="Phone Number"
          :description="errors.phone"
          placeholder="Please fill phone number"
          type="text"
          vertical
          v-model="form.phone"
        />
        <CInput
          label="Telegram ID"
          :description="errors.telegram_id"
          placeholder="Please fill telegram id"
          type="text"
          vertical
          v-model="form.telegram_id"
        />
        <CTextarea
          label="Address"
          :description="errors.address"
          placeholder="Please fill address"
          vertical
          rows="4"
          v-model="form.address"
        />
        <div class="form-group" role="group">
          <label>Receive Notification</label>
          <CSwitch
            class="ml-4"
            :color="'info'"
            v-bind="{ shape: 'pill', size: 'sm' }"
            :checked.sync="receive_notification"
          />
          <small class="form-text text-muted w-100">{{errors.receive_notification}}</small>
        </div>
        <CRow>
          <CCol xl="12"><label>Profile Picture</label></CCol>
          <CCol xl="1">
            <CButton color="danger" class="btn-sm" v-on:click="onFileRemoved"><v-icon name="times" class="v-mid"/></CButton>
          </CCol>
          <CCol xl="11">
            <input ref="inputImage" type="file" @change="onFileChanged" />
          </CCol>
          <CCol xl="12"><span>{{errors.profile_picture}}</span></CCol>
        </CRow>
        <div id="preview">
          <img v-if="url" :src="url" />
        </div>
          <CButton color="info" class="btn-block col-xl-1 float-right padding-5 mt-2" v-on:click="updateProfile()"><v-icon name="pencil-alt" class="v-mid"/> Update</CButton>
      </CForm>
  </CCardBody>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FormUsers',
  components: {
  },
  props: {
  },
  data () {
    return {
      isLoading: false,
      form: {
        uuid: '',
        nrp: '',
        name: '',
        profile_picture: null,
        address: '',
        phone: '',
        email: '',
        telegram_id: '',
        receive_notification: ''
      },
      formData: null,
      url: null,
      img_api: '',
      is_removeimage: null,
      receive_notification: false,
      errors: {
        nrp: '',
        name: '',
        profile_picture: '',
        address: '',
        phone: '',
        email: '',
        telegram_id: '',
        receive_notification: ''
      }
    }
  },
  watch: {
    receive_notification: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          if(val>0) this.form.receive_notification = 1
          else this.form.receive_notification = 0
        }
      }
    },
  },
  async created() {
    this.showData()
  },
  methods: {
    ...mapActions('profile', ['showPFL', 'updatePFL']),
    updateProfile(){
      this.isLoading = true
      this.generateFormData()
      this.updatePFL(this.formData).then((response) => {
        this.showData()
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isLoading = false
      })
    },
    async showData(){
      this.isLoading = true
      await this.showPFL().then((response) => {
        this.form = response
        this.receive_notification = response.receive_notification>0?true:false
        this.url = response.profile_picture
        this.img_api = response.profile_picture
        this.form.profile_picture = null
        this.generateFormData()
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    triggerImg() {
      this.$refs.inputImage.click();
    },
    onFileChanged(e) {
      if(e.target.files[0].size<=1024000) {
        this.form.profile_picture = e.target.files[0];
        this.url = URL.createObjectURL(this.form.profile_picture);
        this.errors.profile_picture = ''
      } else {
        this.errors.profile_picture = 'File max 1MB'
      }
      console.log('size', e.target.files[0].size) // 2697333 B
    },
    onFileRemoved(e) {
      if(this.img_api) {
        this.is_removeimage = 'true'
      } else {
        this.is_removeimage = null
      }
      this.form.profile_picture = null;
      this.url = null;
    },
    generateFormData(){
      this.formData = new FormData()
      this.formData.append('uuid', this.form.uuid)
      this.formData.append('nrp', this.form.nrp)
      this.formData.append('name', this.form.name)
      if(this.form.profile_picture) {
        this.formData.append('profile_picture', this.form.profile_picture)
      }
      if(this.is_removeimage) {
        this.formData.append('is_removeimage', this.is_removeimage)
      }
      this.formData.append('address', this.form.address?this.form.address:'')
      this.formData.append('phone', this.form.phone?this.form.phone:'')
      this.formData.append('email', this.form.email)
      this.formData.append('telegram_id', this.form.telegram_id)
      this.formData.append('receive_notification', this.form.receive_notification)
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.nrp = data.errors.nrp ? data.errors.nrp[0] : ''
        this.errors.name = data.errors.name ? data.errors.name[0] : ''
        this.errors.profile_picture = data.errors.profile_picture ? data.errors.profile_picture[0] : ''
        this.errors.address = data.errors.address ? data.errors.address[0] : ''
        this.errors.phone = data.errors.phone ? data.errors.phone[0] : ''
        this.errors.email = data.errors.email ? data.errors.email[0] : ''
        this.errors.telegram_id = data.errors.telegram_id ? data.errors.telegram_id[0] : ''
        this.errors.receive_notification = data.errors.receive_notification ? data.errors.receive_notification[0] : ''
      }}
    }
  }
}
</script>
<style scoped>
  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #preview img {
    max-width: 40%;
    max-height: 40%;
  }
</style>