<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
          <CRow>
            <CCol xl="6">
              <CRow>
                <CButton color="info" class="col-xl-3" v-on:click="changePage()">
                  <v-icon :name="showpage=='detail'?'pencil-alt':'angle-left'" class="v-mid-back"/> {{showpage=='detail'?'Change':'Back'}}
                </CButton>
                <CButton v-if="showpage=='detail'" color="primary" class="col-xl-3 ml-2" @click="changePass()"><v-icon name="pencil-alt"/> Password</CButton>
              </CRow>
            </CCol>
            <CCol xl="6">
              <CRow class="float-right">
                <CDropdown
                  color="secondary"
                  placement="bottom"
                >
                  <template #toggler>
                    <CButton :color="'secondary'" class="btn-sm">
                      <v-icon name="cog"/>
                    </CButton>
                  </template>
                  <CDropdownItem @click="changeUnit()">
                    <v-icon name="pencil-alt" class="mr-1 v-mid"/> Unit
                  </CDropdownItem>
                </CDropdown>
              </CRow>
            </CCol>
          </CRow>
        </CCardHeader>
          <DetailProfile v-if="showpage=='detail'" :reload="render"/>
          <FormProfile v-if="showpage=='form'"/>
      </CCard>
    </CRow>
    <CModal
      title="Modal Form"
      :show.sync="modalForm"
      :closeOnBackdrop="false"
      size="xl"
    >
      <CCol v-if="isMLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <CForm v-if="!isMLoading">
        <CInput
          label="Old Password"
          :description="errors.old_password"
          placeholder="Please fill old password"
          :type="passcfg.typepass"
          vertical
          v-model="form.old_password"
        >
        <template #append-content>
          <v-icon :name="passcfg.iconpass" class="v-mid" v-on:click="showPassword" style="cursor: pointer;"/>
        </template>
        </CInput>
        <CInput
          label="New Password"
          :description="errors.new_password"
          placeholder="Please fill new password"
          :type="newpasscfg.newtypepass"
          vertical
          v-model="form.new_password"
        >
        <template #append-content>
          <v-icon :name="newpasscfg.newiconpass" class="v-mid" v-on:click="shownewPassword" style="cursor: pointer;"/>
        </template>
        </CInput>
      </CForm>
      <template #header>
        <h6 class="modal-title">Change Password</h6>
        <CButtonClose @click="modalForm = false"/>
      </template>
      <template #footer>
        <CButton @click="modalForm = false" color="light" class="text-left">Cancel</CButton>
        <CButton @click="updatePassword()" color="info">Update</CButton>
      </template>
    </CModal>

    <CModal
      title="Modal Form Unit"
      :show.sync="modalFormunit"
      :closeOnBackdrop="false"
      size="xl"
    >
      <CCol v-if="isMLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <CForm v-if="!isMLoading">
        <div class="form-group" role="group">
          <label>Wind Speed</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.wind_speed_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.wind_speed_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>Wind Direction</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.wind_direction_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.wind_direction_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>Wind Gust Speed</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.wind_gust_speed_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.wind_gust_speed_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>Wind Gust Direction</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.wind_gust_direction_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.wind_gust_direction_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>Temperature</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.temperature_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.temperature_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>Dew Point</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.dew_point_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.dew_point_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>Relative Humidity</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.relative_humidity_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.relative_humidity_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>QNH</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.qnh_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.qnh_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>Density Altitude</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.density_altitude_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.density_altitude_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>Visibility</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.visibility_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.visibility_unit_id}}</small>
        </div>
        <div class="form-group" role="group">
          <label>RVR</label>
          <v-select 
              :reduce="valunits => valunits.value"
              v-model="formunit.rvr_unit_id"
              :options="valunits"
              placeholder="Please select unit"
          />
          <small class="form-text text-muted w-100">{{errorsunit.rvr_unit_id}}</small>
        </div>
      </CForm>
      <template #header>
        <h6 class="modal-title">Setting Units</h6>
        <CButtonClose @click="modalFormunit = false"/>
      </template>
      <template #footer>
        <CButton @click="modalFormunit = false" color="light" class="text-left">Cancel</CButton>
        <CButton @click="updateUnit()" color="info">Update</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DetailProfile from './Detail'
import FormProfile from './Form'

export default {
  name: 'Profile',
  components: {
    DetailProfile,
    FormProfile
  },
  props: {
  },
  data () {
    return {
      showpage: 'detail',
      isMLoading: false,
      modalForm: false,
      modalFormunit: false,
      render: false,
      valunits: [],
      passcfg: {
        showpass: false,
        iconpass: 'eye-slash',
        typepass: 'password'
      },
      newpasscfg: {
        newshowpass: false,
        newiconpass: 'eye-slash',
        newtypepass: 'password'
      },
      form: {
        old_password: '',
        new_password: ''
      },
      formunit: {
        wind_speed_unit_id: '',
        wind_direction_unit_id: '',
        wind_gust_speed_unit_id: '',
        wind_gust_direction_unit_id: '',
        temperature_unit_id: '',
        dew_point_unit_id: '',
        relative_humidity_unit_id: '',
        qnh_unit_id: '',
        density_altitude_unit_id: '',
        visibility_unit_id: '',
        rvr_unit_id: ''
      },
      errors: {
        old_password: '',
        new_password: ''
      },
      errorsunit: {
        wind_speed_unit_id: '',
        wind_direction_unit_id: '',
        wind_gust_speed_unit_id: '',
        wind_gust_direction_unit_id: '',
        temperature_unit_id: '',
        dew_point_unit_id: '',
        relative_humidity_unit_id: '',
        qnh_unit_id: '',
        density_altitude_unit_id: '',
        visibility_unit_id: '',
        rvr_unit_id: ''
      }
    }
  },
  watch: {
    modalForm: {
      handler: function (val, oldVal) {
        if(!val){
          this.form = { 
            old_password: '',
            new_password: ''
          }
          this.errors = { 
            old_password: '',
            new_password: ''
          }
        }
      }
    },
    modalFormunit: {
      handler: function (val, oldVal) {
        if(!val){
          this.formunit = { 
            wind_speed_unit_id: '',
            wind_direction_unit_id: '',
            wind_gust_speed_unit_id: '',
            wind_gust_direction_unit_id: '',
            temperature_unit_id: '',
            dew_point_unit_id: '',
            relative_humidity_unit_id: '',
            qnh_unit_id: '',
            density_altitude_unit_id: '',
            visibility_unit_id: '',
            rvr_unit_id: ''
          }
          this.errorsunit = { 
            wind_speed_unit_id: '',
            wind_direction_unit_id: '',
            wind_gust_speed_unit_id: '',
            wind_gust_direction_unit_id: '',
            temperature_unit_id: '',
            dew_point_unit_id: '',
            relative_humidity_unit_id: '',
            qnh_unit_id: '',
            density_altitude_unit_id: '',
            visibility_unit_id: '',
            rvr_unit_id: ''
          }
        }
      }
    },
  },
  created() {
    this.getUnit()
  },
  methods: {
    ...mapActions('profile', ['changepwPFL', 'getuserUNT', 'changeuserUNT']),
    ...mapActions('unit', ['indexUNT']),
    async getUnit(){
      this.isMLoading = true
      await this.indexUNT().then((response) => {
        response.forEach((item, index) => {
          this.valunits[index] = { value: item.id, label: item.name+' ('+item.symbol+')' }
        })
        this.isMLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isMLoading = false
      })
    },
    showunitData(){
      this.isMLoading = true
      this.getuserUNT().then((response) => {
        this.formunit = {
          wind_speed_unit_id: response?response.wind_speed_unit.id:'',
          wind_direction_unit_id: response?response.wind_direction_unit.id:'',
          wind_gust_speed_unit_id: response?response.wind_gust_speed_unit.id:'',
          wind_gust_direction_unit_id: response?response.wind_gust_direction_unit.id:'',
          temperature_unit_id: response?response.temperature_unit.id:'',
          dew_point_unit_id: response?response.dew_point_unit.id:'',
          relative_humidity_unit_id: response?response.relative_humidity_unit.id:'',
          qnh_unit_id: response?response.qnh_unit.id:'',
          density_altitude_unit_id: response?response.density_altitude_unit.id:'',
          visibility_unit_id: response?response.visibility_unit.id:'',
          rvr_unit_id: response?response.rvr_unit.id:''
        }
        this.isMLoading = false
      }).catch((error) => {
        this.isMLoading = false
      })
    },
    changePage(){
      if(this.showpage=='detail') {
        this.showpage = 'form'
      } else {
        this.showpage = 'detail'
      }
    },
    changePass(){
      this.modalForm = true
    },
    changeUnit(){
      this.modalFormunit = true
      this.showunitData()
    },
    updatePassword(){
      this.isMLoading = true
      this.changepwPFL(this.form).then((response) => {
        this.render = true
        this.isMLoading = false
        this.modalForm = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isMLoading = false
      })
    },
    updateUnit(){
      this.isMLoading = true
      this.changeuserUNT(this.formunit).then((response) => {
        this.render = true
        this.isMLoading = false
        this.modalFormunit = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getErrorUnit(error.data)
        this.isMLoading = false
      })
    },
    showPassword(){
      if(this.passcfg.showpass){
        this.passcfg = {
          showpass: false,
          iconpass: 'eye-slash',
          typepass: 'password'
        }
      } else {
        this.passcfg = {
          showpass: true,
          iconpass: 'eye',
          typepass: 'text'
        }
      }
    },
    shownewPassword(){
      if(this.newpasscfg.newshowpass){
        this.newpasscfg = {
          newshowpass: false,
          newiconpass: 'eye-slash',
          newtypepass: 'password'
        }
      } else {
        this.newpasscfg = {
          newshowpass: true,
          newiconpass: 'eye',
          newtypepass: 'text'
        }
      }
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.old_password = data.errors.old_password ? data.errors.old_password[0] : ''
        this.errors.new_password = data.errors.new_password ? data.errors.new_password[0] : ''
      }}
    },
    getErrorUnit(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errorsunit.wind_speed_unit_id = data.errors.wind_speed_unit_id ? data.errors.wind_speed_unit_id[0] : ''
        this.errorsunit.wind_direction_unit_id = data.errors.wind_direction_unit_id ? data.errors.wind_direction_unit_id[0] : ''
        this.errorsunit.wind_gust_speed_unit_id = data.errors.wind_gust_speed_unit_id ? data.errors.wind_gust_speed_unit_id[0] : ''
        this.errorsunit.wind_gust_direction_unit_id = data.errors.wind_gust_direction_unit_id ? data.errors.wind_gust_direction_unit_id[0] : ''
        this.errorsunit.temperature_unit_id = data.errors.temperature_unit_id ? data.errors.temperature_unit_id[0] : ''
        this.errorsunit.dew_point_unit_id = data.errors.dew_point_unit_id ? data.errors.dew_point_unit_id[0] : ''
        this.errorsunit.relative_humidity_unit_id = data.errors.relative_humidity_unit_id ? data.errors.relative_humidity_unit_id[0] : ''
        this.errorsunit.qnh_unit_id = data.errors.qnh_unit_id ? data.errors.qnh_unit_id[0] : ''
        this.errorsunit.density_altitude_unit_id = data.errors.density_altitude_unit_id ? data.errors.density_altitude_unit_id[0] : ''
        this.errorsunit.visibility_unit_id = data.errors.visibility_unit_id ? data.errors.visibility_unit_id[0] : ''
        this.errorsunit.rvr_unit_id = data.errors.rvr_unit_id ? data.errors.rvr_unit_id[0] : ''
      }}
    }
  }
}
</script>